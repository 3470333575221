<template>
  <div class="editor-wrapper">
    <vue-editor v-model="editorContent" :editor-toolbar="defaultToolbar" />
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import Quill from 'quill'
const sizeStyle = Quill.import('attributors/style/size')

sizeStyle.whitelist = ['14px', '16px', '18px']
Quill.register(sizeStyle, true)

export default {
  name: 'Editor',
  components: {
    VueEditor,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultToolbar: [
        [
          // {
          //   size: ['14px', '16px', '18px'],
          // },
        ],
        ['bold', 'italic', 'underline'], // toggled buttons
        [
          // { align: '' },
          // { align: 'center' },
          // { align: 'right' },
          // { align: 'justify' },
        ],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [
          // { indent: '-1' }, { indent: '+1' }
        ], // outdent/indent
        [{ color: ['#F9375A', '#4CC0CC', '#212832'] }], // dropdown with defaults from theme
        ['link'],
        ['clean'], // remove formatting button
      ],
    }
  },
  computed: {
    editorContent: {
      get() {
        return this.content
      },
      set(updatedContent) {
        this.$emit('update:content', updatedContent)
      },
    },
  },
}
</script>

<style>
.ql-picker-item[data-value='10px']::before,
.ql-picker-label[data-value='10px']::before {
  content: '10px' !important;
}

.ql-picker-item[data-value='12px']::before,
.ql-picker-label[data-value='12px']::before {
  content: '12px' !important;
}

.ql-picker-item[data-value='14px']::before,
.ql-picker-label[data-value='14px']::before {
  content: '14px' !important;
}

.ql-picker-item[data-value='16px']::before,
.ql-picker-label[data-value='16px']::before {
  content: '16px' !important;
}

.ql-picker-item[data-value='18px']::before,
.ql-picker-label[data-value='18px']::before {
  content: '18px' !important;
}

.ql-picker-item[data-value='20px']::before,
.ql-picker-label[data-value='20px']::before {
  content: '20px' !important;
}

.ql-picker-item[data-value='24px']::before,
.ql-picker-label[data-value='24px']::before {
  content: '24px' !important;
}

.ql-picker-item[data-value='30px']::before,
.ql-picker-label[data-value='30px']::before {
  content: '30px' !important;
}

.ql-picker-item[data-value='32px']::before,
.ql-picker-label[data-value='32px']::before {
  content: '32px' !important;
}

.ql-picker-item[data-value='36px']::before,
.ql-picker-label[data-value='36px']::before {
  content: '36px' !important;
}
</style>
